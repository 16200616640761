import logo from "./images/dachstron.png";
import { XOutlined, MessageOutlined } from "@ant-design/icons";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo-coming-soon" />
        <p className="para">
          Introducing DachsTron—the cutest crypto on the block(chain)! 🐾🚀
          Inspired by everyone's favorite long-bodied pup, DachsTron is here to
          bring tail-wagging fun to the world of digital currency. With a loyal
          community and a playful spirit, this meme coin is more than just a
          cute face—it's a token of friendship, fetch-worthy rewards, and
          endless possibilities. Get ready to join the pack and take a walk on
          the wild side with DachsTron—where every coin is a paw-some adventure!
        </p>
        <div className="links">
          <a
            href="https://t.me/+dXLaBwvazXw2Mzdh"
            target="_blank"
            rel="noopener noreferrer"
            className="tele"
          >
            <MessageOutlined />
          </a>
          <a
            href="https://x.com/DachsTron"
            target="_blank"
            rel="noopener noreferrer"
            className="x"
          >
            <XOutlined />
          </a>
        </div>
        <a
          href="https://sunpump.meme/token/TBaY5nzWZ7ity71cZyU8Y23X1ByaF3jxYm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="butt">Get your own now</button>
        </a>
      </header>
    </div>
  );
}

export default App;
